<template>
  <div>
    <draggable
      class="list-group text-end"
      :list="list"
      group="people"
      itemKey="name"
      handle=".handle"
    >
      <template #item="{ element }">
        <div
          class="fs-6 fw-semobold bg-light mb-2 border-top-0 border-right-0 border-left-0 border-bottom border-dashed px-2 d-flex justify-content-between align-items-center border-hover-primary"
        >
          <i
            class="fas fa-grip-vertical fs-5 mx-2 handle text-hover-primary cursor-move"
          ></i>
          <div
            class="flex-fill fs-7"
            :class="
              element.checked === true
                ? 'text-decoration-line-through text-success'
                : ''
            "
          >
            {{ element.title }}
          </div>
          <button
            type="button"
            v-if="editable"
            @click="toggleTodo(element.id)"
            class="btn btn-sm btn-icon btn-bg-light btn-active-color-success w-25px h-25px"
          >
            <i
              class="bi fs-5"
              :class="element.checked ? 'bi-dash-circle' : 'bi-check2-circle'"
            ></i>
          </button>
          <button
            type="button"
            v-if="editable"
            @click="remove(element.id)"
            class="btn btn-sm btn-icon btn-bg-light btn-active-color-danger w-25px h-25px"
          >
            <i class="bi bi-trash fs-5"></i>
          </button>
        </div>
      </template>
    </draggable>
    <form v-if="editable" @submit.prevent="add">
      <div class="d-flex justify-content-between align-items-center">
        <el-input
          size="small"
          v-model="input"
          @focusout="input !== '' ? add() : ''"
          placeholder="افزودن چک لیست ..."
        />

        <button
          type="submit"
          class="btn btn-sm btn-icon btn-bg-light btn-active-color-white btn-active-primary border border-gray-300"
          style="margin-right: 5px; width: 32px; height: 32px"
        >
          <i class="bi bi-plus fs-4"></i>
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { defineComponent, ref, watchEffect } from "vue";
import apiService from "@/core/services/ApiService";

import { TodoSupport } from "@/store/pinia/TodoSupport";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "kt-list-draggable",
  components: {
    draggable,
  },
  props: {
    listData: {
      type: Object,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update"],
  setup(props, { emit }) {
    const TodoSupportHolder = TodoSupport();
    const { toggleTodo } = TodoSupportHolder;

    const list = ref([]);
    const input = ref();
    const add = () => {
      if (
        !input.value ||
        input.value.trim() === "" ||
        [...input.value].length < 1
      )
        return false;
      list.value.push({
        id: list.value ? list.value.length + 1 : 1,
        title: input.value,
      });
      input.value = "";
      sendUpdateEmit();
    };

    const remove = (index) => {
      list.value = list.value.filter((item) => item.id !== index);
      sendUpdateEmit();
    };

    watchEffect(() => {
      if (props.listData) list.value = props.listData;
    });

    const sendUpdateEmit = () => {
      emit("update", list.value);
    };

    return {
      input,
      list,
      add,
      remove,
      toggleTodo,
    };
  },
});
</script>

<style scoped>
.el-input input {
  border: 0px !important;
}
</style>
