
import { defineComponent, reactive, ref, watchEffect } from "vue";
import List from "@/views/pages/other/notes/component/List.vue";
import UploadData from "@/components/uploadFiles/UploadFile.vue";
import { getLocale } from "@/core/services/HelpersFun";
import DatePicker from "vue3-persian-datetime-picker";
import { TodoSupport } from "@/store/pinia/TodoSupport";
import { storeToRefs } from "pinia";
import axios from "axios";
import { platforms } from "@/store/pinia/Platforms";
import { useI18n } from "vue-i18n";
import UserAvatar from "@/components/UserAvatar.vue";
export default defineComponent({
  name: "note-body-modal",
  components: {
    List,
    UploadData,
    DatePicker,
    UserAvatar,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const dialogVisible = ref(false);
    const dialogVisible2 = ref(false);
    const dialogVisible3 = ref(false);
    const dialogVisible4 = ref(false);
    const dialogVisible5 = ref(false);
    const todosHolder = TodoSupport();
    const { targetTodo, comments, admins } = storeToRefs(todosHolder);
    const { sendComments, updateTodo } = todosHolder;
    const comment = ref({
      comment: "",
      file: undefined,
      status: false,
    });

    const platformsHolder = platforms();
    const { platformsList } = storeToRefs(platformsHolder);

    const newNote = reactive({
      title: "",
      description: "",
      thumbnail: "" as any,
      color: "",
      status: "enable",
      category_id: 1,
      sort: "",
      duration: 0,
      platforms: [],
      tags: [],
      todo_list: [],
      holder: {
        tags: [],
        assign_admins: [] as any, // id 1,2
        for_customers: [] as any, // id 5,6
      },
      assign_admins: [] as any, // id 1,2
      for_customers: [] as any, // id 5,6
      setting: {
        reminders: [] as any,
      },
      remove_thumbnail: undefined,
    });

    const checkAddNew = (target) => {
      newNote[target] = newNote[target]
        .concat(newNote.holder[target])
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      updateTodo(newNote);
      newNote.holder[target] = [];
    };

    const removeUser = (target, id) => {
      newNote[target] = newNote[target].filter((element) => element !== id);
      updateTodo(newNote);
    };

    const fileUploadedCallBack = (file) => {
      comment.value.file = file.path;
    };

    const fileUploadedCallBack2 = (file) => {
      newNote.thumbnail = file.path;
      updateTodo(newNote);
    };

    const checkFileInUploading = (status) => {
      comment.value.status = status;
    };

    const updateTodoList = (list) => {
      newNote.todo_list = list;
      updateTodo(newNote);
    };

    watchEffect(() => {
      comment.value.comment = "";
      comment.value.file = undefined;
      comment.value.status = false;

      if (targetTodo.value) {
        newNote.title = targetTodo.value.title;
        newNote.description = targetTodo.value.description;
        newNote.thumbnail = undefined;
        newNote.color = targetTodo.value.color;
        newNote.status = targetTodo.value.status;
        newNote.category_id = targetTodo.value.category.id;
        newNote.sort = targetTodo.value.sort;
        newNote.duration = targetTodo.value.duration
          ? Number(targetTodo.value.duration)
          : 0;
        newNote.platforms = targetTodo.value.platforms ?? [];
        newNote.tags = targetTodo.value.tags;
        newNote.todo_list = targetTodo.value.todo_list;
        newNote.setting.reminders =
          targetTodo.value.setting.reminders &&
          [...targetTodo.value.setting.reminders].length > 0
            ? targetTodo.value.setting.reminders
            : [];
        newNote.assign_admins = targetTodo.value.assign_admins
          ? targetTodo.value.assign_admins.map((item) => {
              return item.id;
            })
          : [];
        newNote.for_customers =
          targetTodo.value && targetTodo.value.for_customers
            ? targetTodo.value.for_customers.map((item) => {
                return item.id;
              })
            : [];
      }
    });

    // reminder
    const addToReminder = () => {
      const tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);
      const tomorrowFormatted = tomorrow
        .toISOString()
        .slice(0, 16)
        .replace("T", " ");

      newNote.setting.reminders.push(tomorrowFormatted);
      updateTodo(newNote);
    };

    const removeFromReminder = (ind) => {
      newNote.setting.reminders = newNote.setting.reminders.filter(
        (item, index) => index !== ind
      );
      updateTodo(newNote);
    };

    const submitButton = ref<HTMLButtonElement | null>(null);
    const sendTaskComments = async () => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      const savedComment = await sendComments(comment.value);
      if (savedComment && savedComment.data) {
        comment.value.comment = "";
        comment.value.file = undefined;
        if (submitButton.value) {
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        }
      } else {
        if (submitButton.value) {
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        }
      }
    };

    // search user start
    const users = ref();
    const loadingSearchUser = ref(false);
    let cancelRequest;

    async function performSearch(searchTerm) {
      loadingSearchUser.value = true;
      // لغو درخواست قبلی اگر وجود داشته باشد
      if (typeof cancelRequest === "function") {
        cancelRequest();
      }

      try {
        const response = await axios.get("/admin/user", {
          params: { q: searchTerm },
          cancelToken: new axios.CancelToken(function executor(c) {
            cancelRequest = c;
          }),
        });
        if (response && response.data) {
          users.value = response.data.data.users.data;
        }
      } catch (error) {
        //
      } finally {
        loadingSearchUser.value = false;
      }
    }
    // search user end

    // search tags start
    const TagList = ref();
    const loadingTagList = ref(false);
    let cancelRequestTagList;

    async function TagListSearch(searchTerm) {
      if (searchTerm.length < 2) return;
      loadingTagList.value = true;
      // لغو درخواست قبلی اگر وجود داشته باشد
      if (typeof cancelRequestTagList === "function") {
        cancelRequestTagList();
      }
      try {
        const response = await axios.get("/admin/tag", {
          params: { search: searchTerm },
          cancelToken: new axios.CancelToken(function executor(c) {
            cancelRequestTagList = c;
          }),
        });
        if (response && response.data) {
          TagList.value = response.data.data.tags;
        }
      } catch (error) {
        //
      } finally {
        loadingTagList.value = false;
      }
    }
    // search tags end

    return {
      users,
      loadingSearchUser,
      performSearch,

      comment,
      newNote,
      targetTodo,
      sendComments,
      comments,
      fileUploadedCallBack,
      fileUploadedCallBack2,
      checkFileInUploading,
      updateTodo,
      updateTodoList,
      addToReminder,
      removeFromReminder,
      getLocale,
      removeUser,
      checkAddNew,
      dialogVisible,
      dialogVisible2,
      dialogVisible3,
      dialogVisible4,
      dialogVisible5,
      admins,
      TagList,
      loadingTagList,
      TagListSearch,
      platformsList,
      translate,
      sendTaskComments,
      submitButton,
    };
  },
});
