
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import draggable from "vuedraggable";
import NoteBody from "./component/NoteBody.vue";
import CategoryList from "@/views/pages/other/notes/category/BoardManagement.vue";
import { TodoSupport } from "@/store/pinia/TodoSupport";
import { storeToRefs } from "pinia";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { platforms } from "@/store/pinia/Platforms";
import JwtService from "@/core/services/JwtService";
import { ElNotification } from "element-plus";
import jwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "schedule-list",
  components: {
    draggable,
    NoteBody,
    CategoryList,
  },
  setup() {
    const dialogVisible = ref(false);
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const notBodyModal = ref(false);

    const popoverVisible = ref({
      assign_admins: false,
      for_customers: false,
      tags: false,
      only_my_tasks: false,
    });

    const platformsHolder = platforms();
    const { platformsList } = platformsHolder;

    const TodoSupportHolder = TodoSupport();
    const {
      todos,
      activeBoardId,
      activeBoardInfo,
      boardsList,
      categories,
      activeCategoryId,
      filter,
      admins,
      checkFiltersHaveValue,
      loadingCategory,
    } = storeToRefs(TodoSupportHolder);

    const {
      fetchTodos,
      setTargetTodo,
      deleteTodo,
      setTargetTodoId,
      fetchTodosCategory,
      sortTodos,
      fetchBoard,
    } = TodoSupportHolder;

    const moveHolder = ref({
      from: 0,
      to: 0,
      newIndex: 0,
      oldIndex: 0,
    });

    const add = () => {
      // data.value.undone.push({ name: "Juan" });
      // data.value.undone.push({ name: "Juan" });
    };

    const replace = () => {
      // data.value.doing = [{ name: "Edgard" }];
    };
    const clone = (el) => {
      return {
        name: el.name + " cloned",
      };
    };

    const log = (evt) => {
      if ("moved" in evt) {
        setTargetTodo("moved", evt["moved"], moveHolder.value);
      } else if ("added" in evt) {
        setTargetTodo("added", evt["added"], moveHolder.value);
      }
      sortTodos(moveHolder.value.from, moveHolder.value.to);
    };

    const move = (evt) => {
      let from = evt.from.getAttribute("id");
      let to = evt.to.getAttribute("id");

      if (from) moveHolder.value.from = from;
      if (to) moveHolder.value.to = to;
    };

    const getCategoryTasks = (cat_id) => {
      if (!todos.value) return [];
      let tasks = todos.value.filter((item) => {
        if (item.category) {
          return item.category.id === cat_id;
        }
      });
      return tasks.sort((a, b) => a.sort - b.sort);
    };

    const categoryTasksDurations = (cat_id) => {
      let duration = 0;
      if (!todos.value) return [];
      let tasks = todos.value.map((item) => {
        if (item.category) {
          if (item.category.id === cat_id && item.duration !== null)
            duration += Number(item.duration);
        }
      });
      return duration;
    };

    const update = (event) => {
      return;
    };

    const openModal = (todo_id) => {
      // $("#kt_modal_note_body").modal("show"); // مثالی از کد باز کردن مدال با استفاده از jQuery
      // const modal = document.querySelector("#kt_modal_note_body");
      // modal.classList.add("show");
    };

    // search user start
    const users = ref();
    const loadingSearchUser = ref(false);
    async function performSearch(searchTerm) {
      loadingSearchUser.value = true;
      const response = await ApiService.query("admin/user", {
        params: { q: searchTerm },
      });

      const results = await response;
      if (results && results.data) {
        loadingSearchUser.value = false;
        users.value = results.data.data.users.data;
      }
    }
    // search user end

    const checkSocketToRefreshList = () => {
      if (JwtService.getUserId()) {
        window.Echo.private("user." + JwtService.getUserId()).listen(
          ".reload_bots",
          () => {
            fetchTodos();
          }
        );
      }
    };

    const downloadCategoryExcel = (cat_id) => {
      return `https://back.inboxino.com/admin/tasks/${cat_id}/download?api_token=${JwtService.getToken()}`;
    };

    const reloadTasks = () => {
      let user_info = JSON.parse(
        String(window.localStorage.getItem("user_info"))
      );
      if (
        user_info.type === "super_admin" ||
        user_info.type === "admin" ||
        user_info.type === "operator"
      ) {
        if (JwtService.getUserId()) {
          window.Echo.private("admins").listen(".reload_tasks", (e) => {
            fetchBoard();
          });
        }
      }
    };

    onMounted(() => {
      fetchBoard();
      fetchTodosCategory();
      fetchTodos();

      checkSocketToRefreshList();
      reloadTasks();
    });

    return {
      users,
      loadingSearchUser,
      performSearch,

      translate,
      log,
      add,

      todos,
      categories,

      getCategoryTasks,
      update,
      move,

      moveHolder,
      openModal,
      deleteTodo,
      setTargetTodoId,
      filter,
      fetchTodos,

      dialogVisible,
      notBodyModal,
      activeCategoryId,
      platformsList,
      downloadCategoryExcel,

      boardsList,
      activeBoardId,
      activeBoardInfo,
      popoverVisible,
      admins,
      checkFiltersHaveValue,
      loadingCategory,

      categoryTasksDurations,
    };
  },
});
